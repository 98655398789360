import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0,2,9,7,3,4,5,6,10,8];

export const dictionary = {
		"/(app)/(properties)/(property-overviews)/developments": [~32,[2,9]],
		"/(app)/(properties)/(property-detail-pages)/developments/[referenceCode]": [~27,[2,7]],
		"/(app)/(listings)/enquiries": [~14,[2,3]],
		"/(app)/(listings)/enquiries/[referenceCode]": [15,[2,4]],
		"/(app)/(listings)/enquiries/[referenceCode]/details": [16,[2,4]],
		"/(app)/leads": [~36,[2]],
		"/(app)/(listings)/listings": [~17,[2,3]],
		"/(app)/(listings)/listings/create": [~26],
		"/(app)/(listings)/listings/[referenceCode]": [~18,[2,5]],
		"/(app)/(listings)/listings/[referenceCode]/attachments": [~19,[2,5]],
		"/(app)/(listings)/listings/[referenceCode]/enquiries": [~20,[2,5]],
		"/(app)/(listings)/listings/[referenceCode]/marketing": [~21,[2,5]],
		"/(app)/(listings)/listings/[referenceCode]/publish": [~22,[6]],
		"/(app)/(listings)/listings/[referenceCode]/publish/success": [23,[6]],
		"/(app)/(listings)/listings/[referenceCode]/units": [~24,[2,5]],
		"/(app)/(listings)/listings/[referenceCode]/units/add": [~25],
		"/(auth)/login": [~44,[12]],
		"/(auth)/login/forgot": [~45,[12]],
		"/(auth)/login/reset": [~46,[12]],
		"/logout": [~51],
		"/maintenance": [52],
		"/(auth)/mfa": [~47,[12]],
		"/(auth)/mfa/enroll": [~48,[12]],
		"/(auth)/mfa/enroll/success": [49,[12]],
		"/(app)/residents": [~37,[2]],
		"/(app)/residents/[referenceCode]": [~38,[2]],
		"/(app)/settings": [~39,[2,11]],
		"/(app)/settings/integrations": [~40,[2,11]],
		"/(app)/settings/users": [~41,[2,11]],
		"/(auth)/signup": [~50,[12]],
		"/terms-and-conditions": [53,[13]],
		"/(app)/transactions": [42,[2]],
		"/(app)/transactions/[referenceCode]": [~43,[2]],
		"/(app)/(properties)/(property-overviews)/units": [~33,[2,9]],
		"/(app)/(properties)/units/valuations": [34,[10]],
		"/(app)/(properties)/units/valuations/create": [35,[10]],
		"/(app)/(properties)/(property-detail-pages)/units/[referenceCode]": [~28,[2,8]],
		"/(app)/(properties)/(property-detail-pages)/units/[referenceCode]/attachments": [~29,[2,8]],
		"/(app)/(properties)/(property-detail-pages)/units/[referenceCode]/specifications": [~30,[2,8]],
		"/(app)/(properties)/(property-detail-pages)/units/[referenceCode]/valuation": [~31,[2,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';